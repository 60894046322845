<template>
    <div class="container">
        <div class="align-items-center justify-content-center row h-100vh m-0">
            <div class="login-container col-lg-6">
                <p class="login-tag">Login</p>
                <small>Login with your Credential</small>
                <b-form role="form" @submit.prevent="onSubmit">
                    <b-form-group id="input-group-2" label="Username:" label-for="input-2">
                        <b-form-input
                            id="input-2"
                            placeholder="User name"
                            v-model="model.username"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Password:" label-for="input-2">
                        <b-form-input
                            id="input-2"
                            placeholder="Password"
                            v-model="model.password"
                            type="password"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <div class="alert alert-danger" style="text-align: center;" v-if="errorMessage"
                         v-html="errorMessage"></div>
                    <div class="row justify-content-lg-center">
                        <div class="col-lg-4">
                            <button class="btn btn-Secondary " type="submit" @click="onSubmit">LOGIN</button>
                        </div>
                        <div class="col-lg-4">
                            <button @click="NewUser" class="btn btn-outline-primary w-100">Register</button>
                        </div>

                    </div>

                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapActions } from 'vuex';

export default {
    name: 'Login',
    props: {
        info_message: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            loading: false,
            errorMessage: '',
            model: {
                username: '',
                password: ''
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        onSubmit () {
            const that = this;
            axios.form(urls.auth.login, this.model).then(function (response) {
                const json = response.data;
                if (json.Success === 'true') {
                    const redirectUrl = sessionStorage.getItem('redirectPath');
                    console.log(json.user[0].role);
                    that.setUser(json.user[0]);
                    if (json.user[0].role === 'Super Admin') {
                        that.$router.push({ path: '/admin/' });
                    } else if (json.user[0].role === 'Shop Admin') {
                        // that.$router.push({ path: '/shop-admin/' + json.user[0].id });
                        that.$router.push({ path: '/shop-admin/' });
                    } else {
                        that.$router.push(redirectUrl || { path: '/user/shops/' + json.user[0].defaultshop + '/products/' });
                    }
                } else {
                    that.errorMessage = json.errors.__all__ || '';
                }
                that.loading = false;
            }).catch(function () {
                that.errorMessage = 'Invalid credentials.';
                that.loading = false;
            });
        },
        NewUser () {
            this.$router.push({ path: '/register/' });
        }
    }
};
</script>
<style>
</style>
